import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type SetState<S> = Dispatch<SetStateAction<S>>;

export function useSessionStorageState<S>(key: string): [S | undefined, SetState<S>];

export function useSessionStorageState<S>(key: string, fallback: S): [S, SetState<S>];

/**
 * Custom hook that provides a state value stored in the browser's session storage.
 * The state value is persisted across page reloads.
 *
 * @template S - The type of the state value.
 * @param key - The key used to store the state value in session storage.
 * @returns A tuple containing the state value and a function to update it.
 */
export function useSessionStorageState<S>(key: string, fallback?: S) {
  const [value, setValue] = useState<S | undefined>(() => {
    const stored = sessionStorage.getItem(key);
    return stored ? JSON.parse(stored) : fallback;
  });

  useEffect(() => {
    if (value === undefined) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [value, key]);

  return [value, setValue] as const;
}
