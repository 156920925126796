import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Theme, css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Cake, Discount as DiscountIcon, EventNote, School } from '@mui/icons-material';

import { currentDateTime } from '@jebel/utils';
import { DiscountStatus, OrganizationStatus } from '@jebel/constants';

import { PaperLayout } from 'shared/components/layouts';
import { DiscountPresentationContent, FeedSkeleton, Typography } from 'shared/components/ui';
import { APP_URL, BIRTHDAYS, DISCOUNTS, EVENTS, SUPPORTERS } from 'shared/constants';
import {
  MemberCommunitySidebarItemsQuery,
  DiscountListItemFragment as Discount,
  MemberCommunitySidebarItemsQueryVariables,
  UserListItemFragment,
} from 'shared/graphql';
import { useModalState, useSchoolConfiguration } from 'shared/hooks';
import { getUsersByBirthday } from 'shared/utils/user';
import { ReedemDiscountModal } from 'features/discounts/components/ReedemDiscountModal';
import { useRandomDiscounts } from 'features/discounts/hooks';
import { OrganizationSidebarItem } from 'features/organizations/components';
import { useRandomSchoolSupporters } from 'features/organizations/hooks';

import { EventCard, UserCard } from './components';
import { MEMBER_COMMUNITY_SIDEBAR_QUERY } from './queries';
import { ModuleContainer } from './components/ModuleContainer';

const containerCSS = css`
  width: 100%;
`;

const emptyContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const emptyLabel = (theme: Theme) => css`
  text-align: center;
  font-size: ${theme.typography.body2.fontSize};
  color: ${theme.palette.text.disabled};
`;

export const viewAllLinkCSS = (theme: Theme) =>
  css`
    color: ${theme.palette.secondary.main};
  `;

interface CommunitySidebarProps {
  items?: string[] | null | undefined;
  isMainContentLoading?: boolean;
}

export function CommunitySidebar({
  items = [SUPPORTERS, BIRTHDAYS, DISCOUNTS, EVENTS],
  isMainContentLoading = false,
}: CommunitySidebarProps) {
  const { configuration: school } = useSchoolConfiguration();

  const {
    show: isDiscountModalOpen,
    params: selectedDiscount,
    open: onDiscountModalOpen,
    close: onDiscountModalClose,
  } = useModalState<Discount>();

  const dateNow = useMemo(() => {
    return currentDateTime();
  }, []);

  const variables: MemberCommunitySidebarItemsQueryVariables = useMemo(() => {
    return {
      dateNow,
      birthdayFilter: {
        birthDate: { is_not_empty: true },
      },
    };
  }, [dateNow]);

  const { data: queryData, loading: queryLoading } = useQuery<
    MemberCommunitySidebarItemsQuery,
    MemberCommunitySidebarItemsQueryVariables
  >(MEMBER_COMMUNITY_SIDEBAR_QUERY, {
    fetchPolicy: 'cache-first',
    variables,
  });

  const { events, users } = useMemo(() => {
    return {
      events: queryData?.eventsList?.items || [],
      users: queryData?.usersList?.items || [],
    };
  }, [queryData]);

  const { data: supporters, loading: supportersLoading } = useRandomSchoolSupporters({
    variables: {
      first: 2,
      filter: {
        status: { equals: OrganizationStatus.Active },
      },
    },
  });

  const { data: discounts, loading: discountsLoading } = useRandomDiscounts({
    variables: {
      first: 3,
      filter: {
        status: { equals: DiscountStatus.Active },
        // https://github.com/jebelapp/jebel/issues/1567
        schools: { some: { id: { equals: school?.id } } },
      },
    },
  });

  const sortedUsersByBirthday = useMemo(
    () => getUsersByBirthday<UserListItemFragment>(users),
    [users],
  );

  const loading = queryLoading || supportersLoading || discountsLoading;

  if (loading || isMainContentLoading) {
    return <FeedSkeleton count={2} />;
  }

  return (
    <PaperLayout css={containerCSS}>
      <ReedemDiscountModal
        isOpen={isDiscountModalOpen}
        onClose={onDiscountModalClose}
        discount={selectedDiscount}
      />

      {items?.includes(SUPPORTERS) && (
        <ModuleContainer
          title="Supporters"
          toViewAll={APP_URL.user.organizations.index}
          icon={<School fontSize="inherit" />}
        >
          {supporters.map(supporter => (
            <OrganizationSidebarItem key={supporter.id} organization={supporter} />
          ))}

          {supporters.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No supporters found</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}

      {items?.includes(DISCOUNTS) && (
        <ModuleContainer title="Discounts" icon={<DiscountIcon fontSize="inherit" />}>
          {discounts.map(discount => (
            <DiscountPresentationContent
              key={discount?.id}
              onRedeem={() => onDiscountModalOpen(discount)}
              discount={discount}
              withRedeem
            />
          ))}

          {discounts.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No active discounts</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}

      {items?.includes(EVENTS) && !!events.length && (
        <ModuleContainer
          title="Upcoming events"
          toViewAll={APP_URL.user.events.index}
          icon={<EventNote fontSize="inherit" />}
        >
          {events.map(event => (
            <EventCard key={event?.id} event={event} />
          ))}

          {events.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No upcoming events</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}

      {items?.includes(BIRTHDAYS) && !!users.length && (
        <ModuleContainer title="Upcoming birthdays" icon={<Cake fontSize="inherit" />}>
          {sortedUsersByBirthday?.map(user => (
            <UserCard key={user?.id} user={user} />
          ))}

          {sortedUsersByBirthday.length === 0 && (
            <Box css={emptyContainer}>
              <Typography css={emptyLabel}>No upcoming birthdays</Typography>
            </Box>
          )}
        </ModuleContainer>
      )}
    </PaperLayout>
  );
}
