import { ApolloError } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { processFilestackUrl } from '@jebel/utils';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet';

import { SESSION_STORAGE_SCHOOL } from 'shared/constants/sessionStorage';
import { SchoolConfigurationFragment, useSchoolConfigurationQuery } from 'shared/graphql';
import { useCurrentUserSchools, useQueryParams, useSessionStorageState } from 'shared/hooks';
import { recordEvent } from 'shared/utils/record';

const SERVER_URL = process.env.REACT_APP_ENDPOINT;

export interface SchoolProvidedContext {
  /**
   * The currently selected school.
   * If no school is selected, this will be `null`.
   * If a school is selected, this will be an object with the `id` of the selected school.
   */
  selectedSchool: SchoolConfigurationFragment | null;

  /**
   * Select a school by `id`.
   * @param id The `id` of the school to select.
   */
  selectSchool(id: string): void;

  /**
   * Whether the school is currently loading.
   */
  loading: boolean;

  /**
   * The error that occurred while fetching the school.
   */
  error: ApolloError | undefined;
}

const SchoolContext = createContext<SchoolProvidedContext>({
  loading: true,
  error: undefined,
  selectedSchool: {},
  selectSchool: () => {},
});

interface Params {
  school: string;
}

function useCreateSchoolContext(): SchoolProvidedContext {
  const [selected, setSelected] =
    useSessionStorageState<SchoolConfigurationFragment>(SESSION_STORAGE_SCHOOL);

  const [params] = useQueryParams<Params>();

  const { data: schools, loading: schoolsLoading } = useCurrentUserSchools();

  const {
    data: response,
    loading,
    error,
  } = useSchoolConfigurationQuery({
    skip: !selected?.id,
    variables: { id: selected?.id as string },
  });

  useEffect(() => {
    const data = response?.school as SchoolConfigurationFragment;

    if (data) {
      // Save the recently selected school to the session storage.
      setSelected(data);
    }
  }, [response, setSelected]);

  useLayoutEffect(() => {
    if (params.school) {
      // Select the school from the URL query params.
      selectSchool(params.school);
    }
  }, [params.school]);

  useEffect(() => {
    if (schoolsLoading || !schools.length) {
      return;
    }

    const [first] = schools;

    if (!first || !first?.id) {
      return;
    }

    const found = schools.find(school => school.id === selected?.id);

    if (!found) {
      // If no school is selected, select the first school.
      setSelected(first);
    }
  }, [selected, schools]);

  const selectSchool = (id: string) => {
    const school = schools.find(school => school.id === id);

    if (!school) {
      return;
    }

    recordEvent('Select School', { 'School ID': id, 'School Name': school.name! });
    setSelected(school);
  };

  const selectedSchool = useMemo(() => selected ?? null, [selected]);

  return {
    selectedSchool,
    selectSchool,

    loading,
    error,
  };
}

const FAVICON_SIZE = 32;
const TOUCH_ICON_SIZE = 192;

export function SchoolProvider(props: PropsWithChildren<unknown>) {
  const theme = useTheme();
  const context = useCreateSchoolContext();

  const school = useMemo(() => context.selectedSchool, [context]);

  const faviconURL = useMemo(() => {
    const source = school?.images?.logo?.downloadUrl;

    if (!source) {
      return '/favicon.ico';
    }

    return processFilestackUrl(source, {
      resize: { width: FAVICON_SIZE, height: FAVICON_SIZE },
    });
  }, [school]);

  const touchIconURL = useMemo(() => {
    const source = school?.images?.logo?.downloadUrl;

    if (!source) {
      return '/favicon.ico';
    }

    return processFilestackUrl(source, {
      resize: { width: TOUCH_ICON_SIZE, height: TOUCH_ICON_SIZE },
    });
  }, [school]);

  const manifestURL = useMemo(() => {
    if (!school) {
      // Return the fallback manifest URL.
      return '/manifest.json';
    }

    // Return the school-specific manifest URL.
    // https://github.com/jebelapp/jebel/issues/1658
    return `${SERVER_URL}/webhook/school/${school.id}/manifest`;
  }, [school]);

  return (
    <SchoolContext.Provider value={context}>
      <Helmet>
        <title>{school?.fullName ?? school?.name ?? 'Jebel'}</title>

        <meta name="theme-color" content={theme.palette.primary.main} />

        <link rel="manifest" href={manifestURL} />

        <link rel="icon" href={faviconURL} />
        <link rel="apple-touch-icon" href={touchIconURL} />
      </Helmet>

      {props.children}
    </SchoolContext.Provider>
  );
}

/**
 * Custom hook to access the `SchoolContext`.
 *
 * This hook provides the current value of the `SchoolContext`. It must be used
 * within a component that is a descendant of a {@linkcode SchoolProvider}. If used outside
 * of a {@linkcode SchoolProvider}, it will throw an error.
 *
 * @throws {Error} If the hook is used outside of a {@linkcode SchoolProvider}.
 * @returns The current context value of the `SchoolContext`.
 */
export function useSchoolContext() {
  const context = useContext(SchoolContext);

  if (!context) {
    throw new Error(`"useSchoolContext" must be used within a "SchoolProvider"`);
  }

  return context;
}
